.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  border-radius: 12px;
  padding-left: 42px;
  padding-right: 42px;
  padding-top: 24px;
  padding-bottom: 24px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    margin-bottom: 20px;
    animation: App-logo-flick infinite 3s;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    animation-delay: 3s;
    -webkit-box-shadow: 11px 14px 29px 0px rgba(30, 190, 201, 1);
    -moz-box-shadow: 11px 14px 29px 0px rgba(30, 190, 201, 1);
    box-shadow: 11px 14px 29px 0px rgba(30, 190, 201, 1);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-flick {
  1.0%,
  2.0%,
  11.0%,
  12.0%,
  40%,
  41%,
  80%,
  80.6%,
  81.2% {
    opacity: 0.99;
  }
  1.5%,
  11.5%,
  40.5%,
  80.3%,
  80.9% {
    opacity: 0.04;
  }
}
